@import './variables';

*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  font-size: 1.6rem;
  font-family: $type-main;
  color: $dark-grey-II;
  line-height: normal;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background: $white;
  @media (min-width: 576px) {
    font-size: 1.6rem;
  }
  margin: 0;
  .MuiTypography-root,
  .MuiTableCell-root {
    font-family: $type-main !important;
  }
}

a {
  text-decoration: underline;
  color: $black;

  &.link {
    transition: 0.15s ease;
    &:active {
      top: 1px;
    }
  }
  &:hover {
    color: $gray-blue-dark;
  }
}
p,
span,
label,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $dark-grey-II;
  word-break: normal;
  word-break: break-word;
  word-wrap: break-word;
}

.background-transparent {
  background: transparent;
}
.wordBreak {
  word-wrap: break-word;
}

h1,
.h1 {
  font-size: 1.55rem;
  font-size: 7.1vw;
  @media (min-width: 576px) {
    font-size: 1.6rem;
  }

  @media (min-width: 768px) {
    font-size: 1.9rem;
  }

  @media (min-width: 1200px) {
    font-size: 2.2rem;
  }
}

h2,
.h2 {
  font-size: 1.88rem;
  @media (max-width: 767.98px) {
    font-size: 1.28rem;
  }
}

h3,
.h3 {
  font-size: 1.56rem;
}

h4,
.h4 {
  font-size: 1.13rem;
}

h5,
.h5 {
  font-size: 0.9rem;
  @media (min-width: 576px) {
    font-size: 1rem;
    font-weight: bold;
  }
}

h6,
.h6 {
  font-size: 0.75rem;
  font-weight: 700;
}

p {
  font-size: $default-font-size !important;
  line-height: 1.4;
}

.small {
  font-size: 25%;
  font-weight: 600;
}

.medium {
  font-size: 50%;
  font-weight: 800;
}

.large {
  font-size: 100%;
  font-weight: 1000;
}

.icon-lg {
  font-size: 3.438rem !important;
}

.icon-md {
  font-size: 1.875rem;
}

.icon-sm {
  font-size: 1rem;
}

.text--right {
  text-align: right;
}

.text--left {
  text-align: left;
}

.text--center {
  text-align: center;
}
