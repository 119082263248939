@import '../../../../theme/scss/variables';

.modal {
  .MuiBox-root {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: $white;
    border: transparent;
    transform: translate(-50%, -50%);
    font-size: $font-size-sm;
    border-radius: 5px;
    &:focus-visible {
      outline: none;
    }
    .modal__header {
      border-bottom: 1px solid $light-grey-IV;
      .MuiTypography-root {
        padding: 28px 40px;
        font-size: 20px;
      }
      .Mui-focusVisible {
        outline: $focus-border;
      }
    }
    .modal__content {
      padding: 0px !important;
    }
  }
  .modal__close-icon {
    position: absolute;
    right: 25px;
    top: 20px;
    color: $dark-grey-II;
  }
  &--small .MuiBox-root {
    width: $modal-sm;
  }

  &--medium .MuiBox-root {
    width: $modal-md;
  }

  &--large .MuiBox-root {
    width: $modal-lg;
  }
}

//STORYBOOK //
.modal-sb {
  .MuiBox-root {
    .modal__content {
      padding: 30px;
    }
  }
}
