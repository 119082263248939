@import '../../../../../theme/scss/variables';

.invoice-table-wrapper {
  background: $white;
  padding: 30px 40px 40px;
  border-radius: 30px;
  box-shadow: $shadow-accordion;
  .invoice-table__mobile {
    display: none;
  }
  .invoice-table__date {
    font-weight: bold;
  }
  .MuiTableHead-root {
    .MuiTableRow-root {
      .MuiTableCell-root {
        font-weight: bold;
        padding: 0 0 12px 0;
        width: 100px;
        font-size: $default-font-size;
        color: $dark-grey-II;
      }
    }
  }
  .MuiTableBody-root {
    .MuiTableRow-root {
      .MuiTableCell-root {
        border-bottom: 1px solid $light-grey-IV;
        padding: 1px 0;
        width: 100px;
        font-size: $default-font-size;
        color: $dark-grey-II;
        height: 50px;
      }
    }
  }
  .invoice-table__mobile-row {
    border-top: 1px solid $light-grey-IV;
    border-bottom: 1px solid $light-grey-IV;
    padding-top: 6px;
    padding-bottom: 6px;
    .MuiTypography-body2 {
      padding: 5px 0;
    }
  }
  .invoice-table__pagination {
    text-align: center;
    .MuiPagination-ul {
      display: inline-flex;
      li {
        button {
          font-size: 12px;
          font-weight: bold;
          color: $dark-grey-II;
          .MuiSvgIcon-root {
            font-size: 24px;
          }
          &:hover {
            background-color: $light-grey-III;
            color: $dark-grey-II;
          }
        }
        .Mui-disabled {
          color: map-get($theme-colors, secondary);
        }
      }
    }
    .MuiPaginationItem-root.Mui-selected {
      background-color: $light-grey-IV;
    }
  }
  .invoice-table__section {
    min-height: 500px;
  }
  .invoice-table__actions {
    .button-wrapper {
      button {
        display: inline;
      }
    }

    .Mui-focusVisible {
      outline: $focus-border;
      opacity: 0.75;
    }
  }
}

.invoice-table__loading-content {
  text-align: center;
  background: $white;
  border-radius: 30px;
  box-shadow: $shadow-accordion;
  padding: 20px 30px;

  .desktop {
    display: block;
    min-height: 606px;
  }
  .mobile {
    display: none;
  }
}

@media (max-width: 600px) {
  .invoice-table-wrapper {
    padding: 20px 20px 30px;
    .invoice-table__desktop {
      display: none;
    }
    .invoice-table__mobile {
      display: block;
    }
    .invoice-table__pagination {
      position: inherit;
      bottom: 0px;
      .pagination-wrapper__root.MuiPagination-root {
        .pagination-wrapper__ul.MuiPagination-ul {
          li {
            button.MuiPaginationItem-root {
              padding: 0 10px !important;
            }
          }
        }
      }
    }
    .invoice-table__section {
      min-height: 454px;
    }
    .invoice-table__actions {
      .button-wrapper {
        button {
          margin-top: 5px;
        }
      }
    }
  }
  .invoice-table__loading-content {
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
      min-height: 540px;
    }
  }
}
