@import '../../../../../theme/scss/variables';

.quick-links {
  .MuiTypography-root {
    text-align: left;
    width: fit-content;
  }
  a,
  .quick-links__links {
    font-size: 12px;
    line-height: 16px;
    font-family: $type-main;
    color: $ccb-blue;
    cursor: pointer;
    text-decoration: underline;
    padding: 0px;
    display: flex;
    align-items: end;
  }
  a:hover,
  .quick-links__links:hover {
    color: $light-blue-V;
    text-decoration: underline;
  }
  a:focus-visible,
  .quick-links__links:focus-visible {
    outline: 2px solid $light-blue-V;
    color: $light-blue-V;
    border-radius: 2px;
  }
  a:active,
  .quick-links__link:active {
    color: $dark-blue;
  }
  .payment-link {
    display: flex;
    justify-content: left;
    .payment__error-icon {
      font-size: 20px;
      margin: auto 0;
      margin-right: 5px;
      color: $red !important;
    }
  }
  .link-wrapper {
    margin-bottom: 16px;
  }
}
.overview__quick-links {
  margin-top: 16px;
}
@media (max-width: 600px) {
  .quick-links {
    margin-top: 10px;
    .MuiTypography-root {
      text-align: left;
    }
    .payment-link {
      justify-content: unset;
    }
  }
}

.sb-show-main {
  .quick-links__links {
    margin-bottom: 16px;
  }
}
