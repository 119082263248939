@import '../../../../../theme/scss/variables';

.payment-modal {
  iframe {
    width: 100%;
    height: 360px;
  }
  .payment-modal__message {
    color: $red;
    margin: -20px 0px 15px;
  }
  .payment-modal__loading {
    height: 360px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .payment-modal__content-wrapper {
    padding: 0px 40px 0 40px;
    .payment-info__note {
      margin: 24px 0 0px;
      .payment-info__required {
        color: $red;
        margin-left: 4px;
      }
    }
  }
  .payment-modal__btn-wrapper {
    display: flex;
    padding: 28px 40px;
    border-top: 1px solid $light-grey-IV;
    .Mui-focusVisible {
      outline: $focus-border;
      opacity: 0.75;
    }
  }
}

@media (max-width: 600px) {
  .modal {
    iframe {
      width: 100%;
    }
    &--small {
      .MuiBox-root {
        width: 90%;
      }
    }
    .payment-modal__message {
      margin: -20px 0px 15px;
    }
  }
}
