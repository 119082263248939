@import '../../../../theme/scss/variables';

.breadcrumb {
  .MuiTypography-root {
    font-size: 48px !important;
    line-height: 55px;
    font-weight: $font-weight-regular;
    color: $white;
  }
  div,
  span {
    .MuiTypography-root {
      opacity: 75%;
      padding: 3px 0;
    }
    &:hover {
      text-decoration: none;
      cursor: pointer;
      opacity: 0.75;
      .breadcrumb__separator {
        opacity: 100;
      }
    }
  }
  .breadcrumb__main-item__wrapper {
    display: inline-flex;
    color: $white;
  }
  .MuiBreadcrumbs-separator {
    display: none;
  }
  .MuiSvgIcon-root {
    font-size: 48px;
    width: auto;
    margin-top: 8px;
  }
}

@media (max-width: 600px) {
  .breadcrumb {
    .MuiTypography-root {
      font-size: 32px !important;
      line-height: 38px;
    }
    .MuiSvgIcon-root {
      font-size: 32px;
      width: auto;
      margin-top: 6px;
    }
  }
}
