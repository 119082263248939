@import '../../../../../theme/scss/variables';

.invoice-no-data-message {
  color: map-get($theme-colors, secondary);
  padding: 80px 0;
  background: $white;
  border-radius: 30px;
  box-shadow: $shadow-accordion;
  text-align: center;
  .invoice-no-data-message__icon {
    display: none;
  }
  .MuiTypography-root {
    color: map-get($theme-colors, secondary);
  }
  .invoice-no-data-message__text {
    font-weight: $font-weight-medium;
    font-size: 21px;
  }
  .invoice-no-data-message__description {
    font-weight: $font-weight-regular;
    font-size: 16px !important;
    padding: 0 20px;
  }
}
