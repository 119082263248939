@import '../../../../theme/scss/variables';

.header {
  background-color: map-get($theme-colors, primary);
  padding: 10px 36px;
  border: none !important;
  height: 260px;
  min-height: auto;
  .header__logo-content {
    display: flex;
    a {
      display: flex;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .header__more-icon-wrapper {
    display: none;
  }
  .header__title {
    line-height: 35px;
    font-weight: $font-weight-bold;
    color: $white;
    letter-spacing: 0.4em;
    margin-top: 1px;
  }
  .header__separator {
    font-size: 22px;
    color: $white;
  }
  .header__back-link-content {
    text-align: right;
    width: 100%;
    margin-top: -2px;
    a {
      text-decoration: none;
      .header__back-icon {
        vertical-align: middle;
        color: $white;
        font-size: 20px;
        margin-right: 5px;
      }
      .header__back-link {
        vertical-align: middle;
        color: $white;
      }
      &:hover {
        opacity: 0.75;
        text-decoration: none;
      }
    }
  }
  .header__user-info {
    .MuiTypography-root {
      vertical-align: middle;
      span {
        color: $white;
      }
    }
  }
  .header__help-icon-link {
    color: $white;
    text-decoration: none;
    &:hover {
      opacity: 0.75;
      text-decoration: none;
    }
    .header__help-icon {
      vertical-align: middle;
      font-size: 20px;
    }
  }
}

@media (max-width: 600px) {
  .header {
    height: 285px;
    padding: 10px 20px;
    .header__user-info {
      text-align: center;
    }
    .header__logo-content {
      text-align: left;
      .logo {
        margin: auto;
      }
      .MuiTypography-root {
        width: auto;
      }
    }
    .header__more-icon-wrapper {
      display: block;
      color: $white;
      .MuiSvgIcon-root {
        font-size: 20px;
      }
    }
    .header__back-link-content {
      display: none;
    }
  }
  .header__back-link-mobile-content {
    text-align: right;
    width: 100%;
    padding: 2px 15px 8px 10px;
    a {
      text-decoration: none;
      .header__back-icon {
        vertical-align: middle;
        color: $dark-grey-II;
        font-size: 20px;
        margin-right: 5px;
      }
      .header__back-link {
        vertical-align: middle;
        color: $dark-grey-II;
      }
      &:hover {
        opacity: 0.75;
        text-decoration: none;
      }
    }
    .header__help-icon-link {
      color: $dark-grey-II;
      text-decoration: none;
      &:hover {
        opacity: 0.75;
        text-decoration: none;
      }
      .header__help-icon {
        vertical-align: middle;
        font-size: 20px;
      }
    }
  }
}
