@import '../../../../../theme/scss/variables';

.overview__plan-list {
  min-height: 65px;
}

.overview__quick-links-loaders,
.overview__summary-loaders {
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
}

@media (max-width: 600px) {
  .overview__quick-links-loaders,
  .overview__summary-loaders {
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
}

//STORYBOOK//
.sb-show-main {
  .overview-base {
    .welcome-banner {
      .MuiTypography-root {
        color: $gray-mid-dark !important;
      }
    }
  }
}
