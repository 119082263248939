@import '../../../../../theme/scss//variables';

.no-payment-method-message {
  color: $dark-grey-II;
  font-size: 12px;
}

.update-payment-btn {
  margin-top: -12px;
  display: flex;
  justify-content: flex-end;
}
