@import '../../../../../theme/scss//variables';

.customer-info {
  $this: &;
  .text-input input {
    margin-bottom: 0px;
  }
  #{$this}__note {
    color: $black;
    font-size: 12px;
    line-height: 16px;
    margin: 0px;
    #{$this}__required {
      color: $red;
      margin-left: 4px;
    }
  }
  .address-line-2 {
    .label-wrapper {
      display: none;
    }
  }
  .eu-vat-id {
    .text-input__field {
      width: 31.66% !important;
    }
  }
}

@media (max-width: 600px) {
  .customer-info {
    .eu-vat-id {
      .text-input__field {
        width: 100% !important;
      }
    }
  }
}
