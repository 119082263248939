@import '../../../../theme/scss/variables';

.coming-soon-message {
  margin-top: 300px;
  .main-text {
    font-weight: 300;
    font-size: 3.75rem !important;
  }
  .sub-text {
    font-weight: 500;
    font-size: 1.25rem !important;
  }
}
