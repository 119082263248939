@import '../../../../../theme/scss/variables';

.overlay {
  background: $light-grey-IV;
  position: fixed;
  height: calc(100vh + 30px);
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.overlay .spinner {
  animation: rotate 0.8s infinite linear;
  border: 5px solid map-get($theme-colors, primary);
  border-right-color: transparent;
  border-radius: 50%;
  height: 64px;
  width: 64px;
  margin: 0 auto;
}
