@import './variables';

// TOASTIFY //
.Toastify {
  .Toastify__toast-container {
    .Toastify__toast-theme--dark {
      background-color: $gray-blue-dark;
      .Toastify__toast-body {
        div {
          font-size: $font-size-label;
        }
      }
      .Toastify__close-button {
        opacity: 100%;
        font-size: 16px;
      }
      .Toastify__progress-bar--success {
        background: $lime-green;
      }
    }
  }
  .Toastify__toast--success {
    .Toastify__toast-theme--dark {
      .Toastify__toast-body {
        .Toastify__toast-icon {
          svg {
            fill: $lime-green;
          }
        }
      }
    }
  }
}

// CONTAINER //
.inner-container {
  margin-top: -155px;
  max-width: 1024px;
  padding-right: 20px;
  padding-left: 20px;
}

// STORYBOOK //
.sb-show-main {
  .inner-container {
    margin-top: 0px;
  }
}
.sb-hide-main {
  .inner-container {
    margin-top: -155px;
  }
}

/* EXTRA SMALL */
@media only screen and (max-width: 600px) {
  .inner-container {
    margin-top: -200px;
  }
}

/* SMALL */
@media only screen and (min-width: 600px) {
  .inner-container {
    margin-top: -200px;
  }
}

/* MEDIUM */
@media only screen and (min-width: 768px) {
  .inner-container {
    margin-top: -170px;
  }
}

/* LARGE  */
@media only screen and (min-width: 992px) {
  .inner-container {
    margin-top: -170px;
  }
}

/* EXTRA LARGE */
@media only screen and (min-width: 1200px) {
  .inner-container {
    margin-top: -170px;
  }
}
