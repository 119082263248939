@import '../../../../../theme/scss//variables';

.invioce-summary {
  background: $light-grey-II;
  padding: 20px 30px 30px;
  border-radius: 30px;
  box-shadow: $shadow-accordion;
  margin-top: 15px;
  label {
    font-size: $font-size-label;
    font-weight: 900;
  }
  .invioce-summary__title {
    font-size: 24px !important;
    font-weight: $font-weight-regular;
    margin-bottom: 35px;
  }
  .invioce-summary__heading {
    font-size: 20px;
    font-weight: bold;
  }
  .invioce-summary__total {
    border-top: 1px solid $light-grey-IV;
    .invioce-summary__total-info-wrapper {
      .invioce-summary__total-info {
        display: inline-grid;
        .invioce-summary__total-sub {
          color: #646363;
          padding-right: 0 !important;
          text-align: right;
          font-size: 12px;
        }
      }
      .invioce-summary__total-amount {
        font-weight: $font-weight-regular;
        font-size: 20px !important;
      }
    }
  }
}

@media (max-width: 600px) {
  .invioce-summary {
    padding: 20px 20px 30px;
    .invioce-summary__title {
      font-size: 20px;
    }
  }
}
