@import '../../../../../../theme/scss/variables';

.plan-info {
  .MuiCardContent-root {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .plan-info__active-bar {
    min-height: 30px;
  }

  .plan-info__btn {
    .MuiButton-root {
      display: flex;
      margin: auto;
    }
  }
  .Mui-focusVisible {
    outline: $focus-border;
    opacity: 0.75;
  }
}

.plan-info--large {
  border-radius: 30px !important;
  box-shadow: rgb(0 0 0 / 15%) 2px 2px 5px 0px !important;
  background-color: $light-grey-II !important;
  .plan-info__title {
    font-size: 32px;
  }
  .plan-info__price {
    font-weight: $font-weight-semi-bold;
    color: $dark-blue-ii;
    font-size: 18px;
  }
  .plan-info__description {
    min-height: 40px;
    font-size: 10px !important;
  }
  .plan-info__billing-preiod {
    text-transform: lowercase;
  }
  .plan-info__sub-description {
    font-size: 12px;
    text-align: center;
    color: $dark-grey-II;
  }
}

.plan-info--large-active {
  background-color: $dark-blue-ii !important;
  .plan-info__active-bar {
    .MuiTypography-root {
      text-align: left;
      margin-left: 40px;
      background: $yellow;
      width: fit-content;
      line-height: 10px;
      padding: 6px 10px;
      color: $dark-blue-ii;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  .plan-info__title {
    color: $white;
  }
  .plan-info__price {
    color: $yellow;
  }
  .plan-info__description {
    color: $white;
    .plan-info__min-count {
      color: $white;
    }
    .plan-info__billing-preiod {
      color: $white;
    }
  }
  .plan-info__sub-description {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: $white;
    span {
      color: $white;
    }
    .plan-info__out-of-seat {
      margin-bottom: -15px;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
}

/*small plan card*/

.plan-info__group {
  /* Button styling */
  .button-group__button-medium {
    display: inline-flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: stretch; /* Align all elements to the button's width */
    width: 100%;
    padding: 10px;
    cursor: pointer;
  }

  /* Plan Info content */
  .plan-info__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  /* Row containing title and price, aligned in one line */
  .plan-info__row {
    display: flex;
    justify-content: space-between; /* Title and Price should be spaced out */
    width: 100%;
    align-items: center; /* Vertically align title and price */
  }

  /* Title styling */
  .plan-info__title {
    text-align: left; /* Left align title */
    flex-grow: 1; /* Allow title to take up available space */
    margin-right: 10px; /* Add space between title and price */
    font-weight: 600 !important;
  }

  /* Price styling */
  .plan-info__price {
    text-align: right; /* Right align price */
    font-weight: 400;
  }

  /* Description styling */
  .plan-info__description {
    margin-top: 10px; /* Add space between title/price and description */
    text-align: left; /* Align description to the left */
    width: 100%; /* Ensure description spans full width */
    font-weight: 400;
    font-size: 11px;
  }

  .plan-info__billing-preiod {
    text-transform: lowercase !important;
  }
}

.plan-info--small-active {
  border: transparent;
  &:hover {
    cursor: no-drop;
  }
}

@media (max-width: 600px) {
  .plan-info--large {
    margin-bottom: 5px;
    .plan-info__title {
      font-size: 26px;
    }
  }
  .plan-info--large-active {
    .plan-info__sub-description {
      margin-bottom: 25px;
    }
  }
}
