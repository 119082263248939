@import '../../../../../theme/scss/variables';

.welcome-banner {
  h4,
  .welcome-banner__text {
    font-size: 48px !important;
    font-weight: $font-weight-regular;
    color: $white;
    line-height: 50px;
  }
  p,
  span {
    font-size: $default-font-size;
    font-weight: $font-weight-regular;
    color: $white;
  }
}

@media (max-width: 600px) {
  .welcome-banner {
    h4,
    .welcome-banner__text {
      font-size: 32px !important;
      line-height: 32px;
    }
  }
}

//STORYBOOK//
.welcome-banner-sb {
  background-color: map-get($theme-colors, primary);
  padding: 20px;
  .MuiTypography-root {
    color: $white !important;
  }
}
