@import '../../../../../theme/scss//variables';

.license-info {
  .label__number-of-seat {
    .label_option {
      margin-left: 0px;
    }
  }
  .label_option {
    color: $grey;
    font-weight: normal !important;
  }
  .button-group__button {
    height: auto;
  }
  .MuiPaper-root {
    background-color: transparent !important;
  }

  .plan-info__group {
    .MuiButtonGroup-root {
      width: 80%;
    }
    .plan-info__label-description {
      display: flex;
      p {
        margin: 0;
      }
      .label-wrapper__description {
        display: inline;
      }
    }

    .button-group__button {
      padding: 16px 20px;
      height: auto;
      .plan-info__title,
      .plan-info__price {
        line-height: 20px;
        font-size: 20px !important;
      }
      .plan-info__description {
        line-height: 16px;
        font-size: 11px;
        margin-top: 4px;
        span {
          line-height: 16px;
          font-size: 11px !important;
        }
      }
    }
    .button-group__selected {
      .plan-info__title,
      .plan-info__price {
        color: $white;
      }
      .plan-info__description {
        color: $white;
        span {
          color: $white;
        }
      }
    }
    .button-group__notSelected {
      .plan-info__title,
      .plan-info__price {
        color: $ccb-blue;
      }

      .plan-info__description {
        color: $dark-grey-II;
      }
    }

    .button-group__button.MuiButton-root.button-group__selected.Mui-disabled {
      opacity: 0.5;
      pointer-events: none;
      background-color: $ccb-blue !important;
      color: $white;
    }
  }

  .billing-period__group {
    .button-group__button {
      padding: 20px 15px;
      width: fit-content !important;
      height: 44px;
      font-size: 14px;
    }
  }

  .billing-period__group--disabled {
    .button-group__selected {
      opacity: 0.5;
      pointer-events: none;
      background-color: $ccb-blue !important;
      color: $white;
    }
  }

  .license-info__note {
    color: $black;
    font-size: 12px;
    line-height: 16px;
    margin: 0px;
    .license-info__required {
      color: $red;
      margin-left: 4px;
    }
  }
  .number-of-seat {
    input {
      width: 140px;
    }
  }

  .quick-links__links {
    font-size: 12px;
    line-height: 16px;
    font-family: $type-main;
    color: $ccb-blue;
    cursor: pointer;
    text-decoration: underline;
    padding: 0px;
    align-items: end;
    margin-left: 4px;
  }
  .quick-links__links:hover {
    color: $light-blue-V;
    text-decoration: underline;
  }
  .quick-links__links:focus-visible {
    outline: 2px solid $light-blue-V;
    color: $light-blue-V;
    border-radius: 2px;
  }
  .quick-links__link:active {
    color: $dark-blue;
  }
}

.plan-item.disabled {
  &:hover {
    cursor: not-allowed;
  }
  .plan-info--small {
    opacity: 50%;
    &:hover {
      cursor: not-allowed;
      background-color: transparent;
    }
  }
}

.license-info__plan-loading {
  font-size: 12px;
  width: 100%;
  display: flex;
  .MuiGrid-item {
    padding-top: 0px;
    padding-bottom: 5px;
  }
}

@media (max-width: 600px) {
  .license-info {
    .label__number-of-seat {
      display: block;
      line-height: 15px;
      .label_option {
        margin-left: 5px;
      }
    }
    .hide_mobile {
      display: none;
    }
    .number-of-seat {
      input {
        width: 100%;
      }
    }
    .plan-info__group {
      .MuiButtonGroup-root {
        width: 100%;
        flex-direction: column;
        .button-group__button {
          border-radius: 0px;
          border: 1px solid;
          margin-bottom: 5px;
        }
      }
    }
    .billing-period__group {
      .MuiButtonGroup-root {
        width: 100%;
        flex-direction: column;
        .button-group__button {
          border-radius: 0px;
          border: 1px solid;
          margin-bottom: 5px;
        }
      }
      .button-group__button {
        width: 100% !important;
      }
    }
  }
  .license-info__plan-loading {
    min-height: 220px;
  }
  .row-item {
    padding-top: 8px;
  }
}
