@import '../../../../../theme/scss/variables';

.summary {
  p {
    font-size: $default-font-size;
    .summary__expired-card {
      color: $light-red;
    }
  }
}

.overview__section-title {
  .MuiTypography-root {
    font-size: 32px !important;
    border-bottom: 1px solid $light-grey-IV;
    font-weight: 400;
  }
}

.overview__summary,
.overview__quick-links {
  .MuiTypography-root {
    margin-bottom: 16px;
  }
}
.overview__summary {
  text-align: right;
  margin-top: 16px;
}
@media (max-width: 600px) {
  .overview__section-title {
    .MuiTypography-root {
      font-size: 26px !important;
    }
  }

  .overview__summary {
    text-align: left;
    margin-top: 5px;
  }
}
