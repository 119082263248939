@import '../../../../../theme/scss/variables';

.custom-message {
  border: 1px solid map-get($theme-colors, primary);
  box-shadow: 2px 2px 10px 0 rgb(0 0 0 / 15%);
  background-color: $white;
  margin-bottom: 20px !important;
  border-radius: 30px;
  padding: 25px 48px !important;
  font-size: 12px;
  font-weight: 600;
  margin-top: 15px;
  text-align: left !important;
  &__error {
    border: 1px solid $red;
    background-color: $pink;
    color: $red;
    text-transform: uppercase;
  }
}

@media (max-width: 600px) {
  .custom-message {
    margin-top: 25px;
    padding: 20px !important;
  }
}
