@import '../../../../../theme/scss/variables';

.order-confirmation-modal {
  .order-confirmation-modal__title {
    margin-right: 20px;
  }
  .order-confirmation-modal__content {
    padding: 15px 40px;
  }
  .order-confirmation-modal__btn-wrapper {
    display: flex;
    padding: 28px 40px;
    border-top: 1px solid $light-grey-IV;
    .Mui-focusVisible {
      outline: $focus-border;
    }
  }

  .order-confirmation-modal__banner {
    background: $light-grey-II;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 400;
    margin: 5px 0;
    font-size: $default-font-size;
  }

  .badge {
    width: max-content;
    .MuiBadge-badge {
      padding: 10px;
      width: max-content;
      transform: none;
      transform-origin: unset;
      position: relative;
      right: auto;
    }
    /* Font weight*/
    &--light .MuiBadge-badge {
      font-weight: $font-weight-light;
    }

    &--bold .MuiBadge-badge {
      font-weight: $font-weight-bold;
    }
  }

  .order-info {
    .order-info__row {
      .order-info__col,
      .order-tax-info {
        margin-top: 20px;
      }
      .order-info__status {
        width: max-content;
        .MuiBadge-badge {
          padding: 10px;
          width: 75px;
          transform: none;
          transform-origin: unset;
          position: relative;
          right: auto;
        }
        &--light .MuiBadge-badge {
          font-weight: $font-weight-light;
        }
      }
      .MuiTableCell-root {
        font-size: $default-font-size;
      }
      .order-info__gross-value {
        font-size: 20px;
        font-weight: 600;
        padding-left: 8px;
      }
      .order-info-total {
        display: block;
        margin-bottom: 15px;
      }
    }
    .order-info__no-data-message td {
      text-align: center;
      margin-top: 15px;
      font-size: 12px;
    }
    .order-info__desktop {
      display: contents;
      .order-info__row {
        .MuiTableCell-root {
          border-bottom: 1px solid rgba(224, 224, 224, 1);
        }
      }
    }
    .order-info__mobile {
      display: none;
      .order-info__row {
        .MuiTableCell-root {
          border-bottom: 1px solid rgba(224, 224, 224, 1);
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .modal--medium .MuiBox-root {
    width: 90%;
  }
  .order-confirmation-modal {
    .order-confirmation-modal__content {
      height: 415px;
      overflow: auto;
    }
    .order-info {
      .order-info__row {
        .order-info__col,
        .order-tax-info {
          vertical-align: text-bottom;
        }
        .order-info__status {
          .MuiBadge-badge {
            margin-bottom: 5px;
          }
        }
      }
      .order-info__desktop {
        display: none;
      }
      .order-info__mobile {
        display: block;
        .order-info__row {
          .MuiTableCell-root {
            padding-left: 8px;
            div {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .modal--medium .MuiBox-root {
    width: 90%;
  }
}

@media only screen and (min-width: 768px) {
  .modal--medium .MuiBox-root {
    width: 800px;
  }
}
