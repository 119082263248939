@import '../../../../../theme/scss/variables';

.plan-manager {
  width: 100%;
  .according {
    margin-top: 15px;
    .MuiButtonBase-root.MuiAccordionSummary-root.Mui-focusVisible {
      outline: 2px solid $ccb-blue;
      outline-offset: -2px;
      border-radius: 30px;
      background-color: transparent;
    }
  }
  .MuiAccordionSummary-root {
    padding: 0;
  }
  .MuiAccordion-root {
    border: 1px solid $light-grey-II;
    box-shadow: $shadow-accordion;
    background-color: $white;
    margin-bottom: 20px !important;
    border-radius: 30px;
    .MuiAccordionSummary-root {
      p {
        font-size: 24px !important;
        color: $dark-grey-II;
        font-weight: 400;
      }
      .MuiSvgIcon-root {
        color: $grey;
      }
    }
    .MuiCollapse-root {
      .MuiAccordionDetails-root {
        padding: 0px;
      }
    }

    &::before {
      height: 0;
    }
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
  .MuiAccordionSummary-content,
  .MuiAccordionSummary-content.Mui-expanded {
    align-items: center;
    margin: 0;
    .payment-info {
      width: auto;
      .summary {
        p {
          margin-bottom: 16px !important;
        }
      }
    }
  }
  .MuiButtonBase-root.MuiAccordionSummary-root {
    padding: 32px 52px;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px;
  }
  .payment-form__fixed-section {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: $white;
    box-shadow: 0px 0px 10px 0 rgb(0 0 0 / 15%);
  }
  .payment-form__scrollable-section {
    margin-bottom: 150px;
  }
  .payment-form__button-container {
    max-width: 1024px;
    padding-right: 20px;
    padding-left: 20px;
    .payment-form__button-wrapper {
      height: 78px;
      display: flex;
      .Mui-focusVisible {
        outline: $focus-border;
        opacity: 0.75;
      }
    }
    .payment-form__button-wrapper-loader {
      padding-top: 20px;
    }
  }
  .MuiGrid-item {
    .MuiAccordionSummary-expandIconWrapper {
      margin-left: 8px;
    }
  }
  .payment__error {
    display: flex;
    .MuiSvgIcon-root {
      color: $red !important;
    }
  }

  .payment-info {
    .summary {
      p {
        margin-bottom: 16px !important;
      }
    }

    .quick-links {
      .MuiTypography-root {
        text-align: right;
        width: 100%;
        .quick-links__links {
          display: block;
        }
      }
    }
  }
  .payment-info-wrapper,
  .customer-info,
  .license-info {
    padding: 0px 52px 32px 52px;
  }
}

@media (max-width: 600px) {
  .plan-manager {
    .according {
      margin-top: 0px;
    }
    .MuiAccordion-root {
      .MuiAccordionSummary-root {
        padding: 30px 20px;
        p {
          font-size: 20px !important;
        }
      }
      .MuiAccordionDetails-root {
        padding: 0px 20px 30px;
      }
    }
    .payment-info {
      .quick-links {
        margin-top: 0px;
        .MuiTypography-root {
          text-align: left;
          width: 100%;
          .quick-links__links {
            display: block;
          }
        }
      }
    }
    .payment-info-wrapper,
    .customer-info,
    .license-info {
      padding: 0px 20px 32px 20px;
    }
  }
  .payment__error {
    .MuiSvgIcon-root {
      font-size: 20px !important;
    }
  }
}

//STORYBOOK//
.sb-show-main {
  .payment-form__fixed-section {
    position: absolute;
    width: 100%;
    box-shadow: none;
    border-top: 1px solid $light-grey-II;
  }
  .breadcrumb {
    .MuiTypography-root {
      color: $gray-mid-dark !important;
    }
  }
}
