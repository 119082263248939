.plan-grid {
  display: flex;
}

.en-plan {
  .plan-info--large {
    .plan-info__description {
      min-height: 50px;
    }
    .plan-info__sub-description {
      margin-bottom: 55px;
    }
    .plan-info__title {
      margin-bottom: 35px;
    }
    .plan-info__student-count {
      margin: 30px 0 -15px 0;
    }
  }
}

@media (max-width: 600px) {
  .en-plan {
    .plan-info--large {
      .plan-info__description {
        min-height: 35px;
      }
    }
  }
}
