@import '../../../../theme/scss/variables';

.error-page-message {
  h1 {
    font-size: 48px;
    font-weight: $font-weight-regular;
    color: $white;
    margin-bottom: 45px;
  }
  .message-wrapper {
    color: #767272;
    padding: 80px 25px;
    background: $white;
    border-radius: 30px;
    box-shadow: $shadow-accordion;
    text-align: center;
    .error-message__icon {
      display: none;
    }
    .MuiTypography-root {
      color: #767272;
    }
    .error-message__text {
      font-weight: $font-weight-medium;
      font-size: 21px;
    }
    .error-message__description {
      font-weight: $font-weight-regular;
      font-size: 16px !important;
      padding: 0 150px;
    }
  }
  .back-to-app {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lan-tag {
    color: $white;
  }
}

@media (max-width: 600px) {
  .error-page-message {
    h1 {
      font-size: 32px;
    }
    .message-wrapper {
      .error-message__description {
        padding: 0;
      }
    }
  }
}
