@import '../../../../theme/scss/variables';

.section-title {
  width: 100%;
  h6 {
    font-weight: $font-weight-regular;
    font-size: 20px;
    border-bottom: 1px solid $light-grey-IV;
  }
}
