@import 'node_modules/@fontsource/open-sans';
@import '@fontsource/open-sans/300.css';
@import '@fontsource/open-sans/400.css';
@import '@fontsource/open-sans/500.css';
@import '@fontsource/open-sans/600.css';
@import '@fontsource/open-sans/700.css';
@import '@fontsource/open-sans/800.css';

// COLORS //
//Brand blues
$light-blue-I: #eef6fb;
$light-blue-II: #e6f2f9;
$light-blue-III: #9fcce9;
$light-blue-IV: #78c2e9;
$light-blue-V: #128ee0;
$dark-blue: #0e6eaf;
$dark-blue-ii: #0a4f7d;
$ccb-blue: #067bc5;

// Neutrals
$white: #fff;
$extra-light-grey: #fafafa;
$light-grey-I: #f5f5f5;
$light-grey-II: #f0f0f0;
$light-grey-III: #eaeaea;
$light-grey-IV: #dddddd;
$grey: #999999;
$grey-II: #6c7179;
$dark-grey: #4e545d;
$dark-grey-II: #404040;
//Neutrals: discontinued
$super-silver: #efefef;
$gray-smoke: #ededed;
$gray-mid-dark: #777;
$gray-blue-dark: #545c64;
$gray-tundora: #444444;
$black: #000000;

//Semantic colors
//Red
$light-red-I: #fdeeed;
$light-red-II: #fce5e3;
$light-red-III: #f5aaa5;
$light-red-IV: #ff4f2a;
$light-red-V: #e84036;
$red: #e4261b;
$dark-red: #c92117;
//Red: discontinued
$light-red: #ff5652;

//Yellow
$yellow: #ffde1f;

//Green
$green: #1cba89;
//Green: discontinued
$lime-green: #30e162;

//Pink
$pink: #ffe6e6;

$theme-colors: (
  primary: $ccb-blue,
  secondary: $grey,
);

// SHADOWS
$shadow-primary: 0 -2px 35px -8px rgba(161, 176, 205, 0.25);
$shadow-primary-with-sub-tabs: 0 20px 35px -8px rgba(161, 176, 205, 0.25);
$shadow-tabs: 0 5px 35px rgba(161, 176, 205, 0.25);
$shadow-accordion: 2px 2px 5px 1px rgba(0, 0, 0, 0.15);

// FORMS //
$input-bg: color(white);
$input-border-radius: 2px;
$input-placeholder-color: $dark-grey-II;
$input-font-size: 0.8125rem;
$input-padding-y: 0.875rem;
$input-padding-x: 1.375rem;
$input-line-height: 1;
$input-padding-y-xs: 0.5rem;
$input-padding-x-xs: 0.75rem;
$input-padding-y-sm: 0.5rem;
$input-padding-x-sm: 0.81rem;
$input-padding-y-lg: 0.94rem;
$input-padding-x-lg: 1.94rem;

// FONTS//
$type-main: 'Open Sans', sans-serif;
$type-sub: Arial, sans-serif;
$text-muted: #9c9fa6;
$body-color: #343a40;
$font-weight-bold: 900;
$font-weight-semi-bold: 700;
$font-weight-medium: 600;
$font-weight-regular: 400;
$font-weight-light: 100;

$default-font-size: 12px;
$font-size-xs: 0.6rem;
$font-size-sm: 0.8rem;
$font-size-md: 1.4rem;
$font-size-lg: 3.2rem;
$font-size-label: 12px;

// SIZE VARIABLES //
$size-sm: 30px;
$size-md: 60px;
$size-lg: 100px;

$precentage-of-half: 50%;

// SIZE VARIABLES FOR MODAL //
$modal-sm: 440px;
$modal-md: 800px;
$modal-lg: 1200px;

//ACCESSIBILITY
$focus-border: solid 1px $black;
